import Image from 'next/image';
import Link from 'next/link';
import {
  Flex,
  Heading,
  Text,
  Link as ChakraLink,
  Icon,
} from '@chakra-ui/react';
import { RiArrowLeftLine } from 'react-icons/ri';

export default function Custom404() {
  return (
    <Flex
      direction="column"
      h="100vh"
      alignItems="center"
      justifyContent="center"
      padding="4"
    >
      <Image
        src="/images/404.svg"
        alt="Picture of the author"
        width={350}
        height={200}
      />
      <Heading size="2xl" mt="8">
        Oooops!
      </Heading>
      <Text textAlign="center">Página não econtrada</Text>
      <ChakraLink as={Link} colorScheme="green" href="/dashboard">
        <a>
          <Flex
            mt="6"
            alignItems="center"
            _hover={{
              color: 'green.300',
            }}
          >
            <Icon as={RiArrowLeftLine} />
            <Text ml="2">Voltar para o dashboard</Text>
          </Flex>
        </a>
      </ChakraLink>
    </Flex>
  );
}
